body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: #ec2224;
}

.action:hover {
  cursor: pointer;
  font-weight: bold;
  color: #ec2224;
}

.simplebar-track {
  border-radius: 10px;
  width: 7px !important;
  background-color: #efefef;
}

.simplebar-scrollbar::before {
  background-color: #989696 !important;
  opacity: 1 !important;
  border-radius: 1.5px !important;
  width: 3px !important;
  background-clip: padding-box !important;
  top: 5px !important;
  bottom: 5px !important;
}
